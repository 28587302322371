/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { Flex, jsx } from 'theme-ui'

const RowContainer = (props) => (
  <Flex
    sx={{
      flex: 1,
      justifyContent: 'center',
    }}
  >
    <Flex
      {...props}
      style={{
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: 1601,
      }}
    />
  </Flex>
)

export default RowContainer
