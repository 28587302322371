/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { cloneElement } from 'react'
import { Box, jsx } from 'theme-ui'
import { useThemeUI } from 'theme-ui'

import TextBlock from '../TextBlock'
import Link from '../Link'
import Icon from '../Icon'

export const ROW_LAYOUT_TYPE = {
  THREE_IN_A_ROW: 410,
  FOUR_IN_A_ROW: 300,
}

const FeatureBox = ({
  icon,
  heading,
  variant = 'heading',
  detail,
  link = null,
  linkText = '',
  rowWidth = ROW_LAYOUT_TYPE.THREE_IN_A_ROW,
}) => {
  const {
    theme: { rawColors },
  } = useThemeUI()
  return (
    <Box
      sx={{
        height: 'auto',
        minWidth: '360px',
        maxWidth: rowWidth,
        marginTop: 4,
        paddingX: 4,
      }}
    >
      {typeof icon === 'string' && (
        <Box sx={{ marginBottom: 4 }}>
          <Icon size={40} color={rawColors.summerSky} iconName={icon} />
        </Box>
      )}
      <TextBlock heading={heading} variant={variant} textBeforeLink={detail} />
      {link && (
        <Box pt={4}>
          <Link href={link} text={linkText} />
        </Box>
      )}
    </Box>
  )
}

export default FeatureBox
