import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

import arrowLeft from '../../assets/ionicons/arrow-back.svg'
import arrowRight from '../../assets/ionicons/arrow-forward.svg'
import add from '../../assets/ionicons/add.svg'
import alarm from '../../assets/ionicons/alarm.svg'
import analytics from '../../assets/ionicons/analytics.svg'
import archive from '../../assets/ionicons/archive.svg'
import barChart from '../../assets/ionicons/bar-chart.svg'
import barCode from '../../assets/ionicons/barcode.svg'
import business from '../../assets/ionicons/business.svg'
import calendar from '../../assets/ionicons/calendar.svg'
import card from '../../assets/ionicons/card.svg'
import cart from '../../assets/ionicons/cart.svg'
import cash from '../../assets/ionicons/cash.svg'
import checkbox from '../../assets/ionicons/checkbox.svg'
import chatbubble from '../../assets/ionicons/chatbubble.svg'
import clipboard from '../../assets/ionicons/clipboard.svg'
import documents from '../../assets/ionicons/documents.svg'
import exit from '../../assets/ionicons/exit.svg'
import images from '../../assets/ionicons/images.svg'
import layers from '../../assets/ionicons/layers.svg'
import mail from '../../assets/ionicons/mail.svg'
import people from '../../assets/ionicons/people.svg'
import personAdd from '../../assets/ionicons/person-add.svg'
import phone from '../../assets/ionicons/phone.svg'
import pieChart from '../../assets/ionicons/pie-chart.svg'
import reader from '../../assets/ionicons/reader.svg'
import schedule from '../../assets/ionicons/schedule.svg'
import statsChart from '../../assets/ionicons/stats-chart.svg'
import swapHorizontal from '../../assets/ionicons/swap-horizontal.svg'
import sync from '../../assets/ionicons/sync.svg'
import tablet from '../../assets/ionicons/tablet.svg'
import time from '../../assets/ionicons/time.svg'
import train from '../../assets/ionicons/train.svg'

const iconNames = {
  arrowLeft,
  arrowRight,
  add,
  alarm,
  analytics,
  archive,
  calendar,
  barChart,
  barCode,
  business,
  card,
  cart,
  cash,
  checkbox,
  chatbubble,
  clipboard,
  documents,
  exit,
  images,
  layers,
  mail,
  people,
  personAdd,
  phone,
  pieChart,
  reader,
  schedule,
  statsChart,
  swapHorizontal,
  tablet,
  sync,
  time,
  train,
}

const StyledSVG = styled(SVG)`
  width: ${({ size }) => size}px;
  height: auto;
  & path {
    stroke: ${({ color }) => color};
  }
  & line {
    stroke: ${({ color }) => color};
  }
  & rect {
    stroke: ${({ color }) => color};
  }
  & polyline {
    stroke: ${({ color }) => color};
  }
  & ellipse {
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
  }
  & circle {
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
  }
`

const Icon = ({ size, color, iconName }) => (
  <StyledSVG color={color} size={size} src={iconNames[iconName]} />
)

export default Icon
